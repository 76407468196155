/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import InputFields from "../../InputFields";
import Arbitration from "../../../global/pdf/arbitration.pdf";
import Education from "../../../global/pdf/education.pdf";
// import Disclosures from "../../../global/pdf/disclosures.pdf";

class GetStarted extends Component {
  render() {
    return (
      <React.Fragment>
        <h1 className="investFormHeader">Get Started</h1>
        <br />
        <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <InputFields
              label="I certify that all of the information provided by me is true and complete. I agree to keep this information up to date and will notify Vesterr of any changes."
              type="checkbox"
              inputType="checkbox"
              model=".started_1"
              // standardLabel={true}
            />
          </Col>
        </Row>
        <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <InputFields
              label="I understand the investments are illiquid and speculative, and that I may lose my entire investment. I am in a financial position to bear the loss of my investment. I acknowledge that, by making an investment, there are restrictions on canceling my investment and receiving a return of my funds. I understand I may cancel my investment at any time up until 48-hours prior to the target offering deadline. I acknowledge the securities I purchase are illiquid and may be difficult to resell."
              type="checkbox"
              inputType="checkbox"
              model=".started_2"
              // standardLabel={true}
            />
            {/* <a href="#/" className="downloadLink" title="Download">
              Download
            </a> */}
          </Col>
        </Row>
        <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <InputFields
              label={
                <>
                  I agree to receive all communications from Vesterr
                  electronically. By marking this checkbox, I agree to the{" "}
                  <a
                    href={Arbitration}
                    className="hyperLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    pre-dispute arbitration agreement
                  </a>
                  . I acknowledge I have reviewed the{" "}
                  <a
                    href={Education}
                    // href="/education"
                    className="hyperLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    investor education materials.
                  </a>
                  {/* {" "}
                  and{" "}
                  <a
                    href={Disclosures}
                    // href="/disclosures"
                    className="hyperLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    fee disclosure materials.
                  </a> */}
                </>
              }
              type="checkbox"
              inputType="checkbox"
              model=".started_3"
            />
          </Col>
        </Row>
        {/* <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <InputFields
              label={
                <span className="soureRegular">
                  I agree to the{" "}
                  <a
                    className="checkboxLabelLink"
                    href="https://google.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    className="checkboxLabelLink"
                    href="https://google.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </span>
              }
              type="checkbox"
              inputType="checkbox"
              model=".signup_1"
            />
          </Col>
        </Row> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
});

export default connect(mapStateToProps)(GetStarted);
