/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import InputFields from "../../../InputFields";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

class InvestorDetails extends Component {
  addFiles(file) {
    const { dispatch } = this.props;
    const files = [file];
    dispatch(actions.change("investForm.primary_investor.file", files));
  }

  removeFile(fileName) {
    const { investForm, dispatch } = this.props;
    const { primary_investor } = investForm;
    let files = [];
    files = primary_investor?.file;
    files = files?.filter((x) => x.name !== fileName);
    dispatch(actions.change("investForm.primary_investor.file", files));
  }

  removeExistingFile(fileName) {
    const { investForm, dispatch } = this.props;

    dispatch(
      actions.change(
        "userActions.deleted_document_id",
        investForm.primary_investor.document?.documentId
      )
    );
    dispatch(actions.change("investForm.primary_investor.document", null));
    // this.setState({ [type]: files });
  }

  render() {
    const {
      options,
      investFormCurrentModel,
      investForm,
      setPhoneErrors,
      dispatch,
    } = this.props;

    const DocumentUploader = (type, subType) => {
      const handleChangeStatus = ({ file, meta }, status) => {
        if (status === "done") {
          this.addFiles(file);
          dispatch(actions.change("investForm.uploadFileError", false));
        } else if (status === "removed") {
          this.removeFile(file?.name);
          dispatch(actions.change("investForm.uploadFileError", false));
        } else if (status === "error_file_size") {
          dispatch(actions.change("investForm.uploadFileError", true));
        }
      };
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept="image/png,image/jpg,image/jpeg,.pdf"
          maxSizeBytes={1000000}
          PreviewComponent={null}
          inputContent="Upload"
        />
      );
    };

    let investorType =
      investForm?.investment_info?.type ||
      investForm?.investment_info?.entityType;

    const idUploadRequired =
      !investForm?.primary_investor?.document &&
      !investForm?.primary_investor?.file?.length;

    return (
      <>
        <Row className="inputRow">
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType || "Entity"} Name`}
              type="text"
              inputType="text"
              value={investForm?.primary_investor?.entityName}
              model=".entityName"
              tooltip={
                "This must be the investor company's full legal name and should match the bank account that will be used to fund this investment."
              }
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Country (Must be incorporated or headquartered in the US)`}
              type="select"
              inputType="select"
              model=".primCountry"
              // value={239} // United States Default Value
              acceptedValue="US"
              acceptedValueMessage="Must be incorporated or headquartered in the US"
              value={investForm?.primary_investor?.primCountry}
              options={options?.countries || []}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Street Address`}
              type="text"
              inputType="text"
              model=".primAddress1"
              value={investForm?.primary_investor?.primAddress1}
              placeholder="Street Address"
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Apartment, suite or unit number"
              type="text"
              inputType="text"
              model=".primAddress2"
              value={investForm?.primary_investor?.primAddress2}
              placeholder="Apartment, suite or unit number"
              ignore={true}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="City"
              type="text"
              inputType="text"
              model=".primCity"
              value={investForm?.primary_investor?.primCity}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="State"
              type="select"
              inputType="select"
              model=".primState"
              value={investForm?.primary_investor?.primState}
              options={options?.states || []}
              // excludedValues={["NY"]}
              // excludedValuesMessage="At the current time, we do not accept New York investors."
            />
            {/* <p className="formInputError">
              At the current time, we do not accept New York investors.
            </p> */}
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Zip Code"
              type="text"
              inputType="text"
              model=".primZip"
              value={investForm?.primary_investor?.primZip}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Email`}
              type="email"
              inputType="text"
              model=".emailAddress"
              value={investForm?.primary_investor?.emailAddress}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Phone`}
              type="tel"
              inputType="customPhone"
              model={`${investFormCurrentModel?.model}.phone`}
              value={investForm?.primary_investor?.phone}
              setPhoneErrors={(v) => setPhoneErrors(v)}
            />
            {!investForm?.primary_investor?.phone &&
            investForm?.showFormError ? (
              <p className="formInputError">Required*</p>
            ) : null}
            {investForm?.primary_investor?.phone &&
            investForm?.showPhoneFormError &&
            investForm?.showFormError ? (
              <p className="formInputError">
                {investForm?.showPhoneFormError}*
              </p>
            ) : null}
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} EIN`}
              type="text"
              inputType="text"
              model=".ein"
              value={investForm?.primary_investor?.ein}
              minLength={9}
              maxLength={9}
              tooltip="Social Security Number, EIN, or tax identification number for your country (e.g. your Social Insurance Number). 
                  This is used for identity verification, investor screening, and tax forms that may be associated with this investment."
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Date of Formation"
              type="date"
              inputType="date"
              model=".formationDate"
              tooltip="Formation date for the entity."
              value={investForm?.primary_investor?.formationDate}
            />
          </Col>
          {/* <Col lg={6} className="inputCol">
            <InputFields
              label="State of Formation"
              type="select"
              inputType="select"
              // model=".region"
              // value={investForm?.primary_investor?.region}
              model=".region_formed_in"
              value={investForm?.primary_investor?.region_formed_in}
              options={options?.states || []}
              tooltip="The state where the company/trust was formed."
              excludedValues={["NY"]}
              // excludedValuesMessage="At the current time, we do not accept New York investors."
            />
            <p className="formInputError">
              At the current time, we do not accept New York investors.
            </p>
          </Col> */}
          <Col lg={12} className="inputCol">
            <Row>
              <Col lg={12}>
                <InputFields
                  label="I am a US citizen, US resident, or other US person."
                  type="radio"
                  inputType="radio"
                  value="U.S. citizen"
                  model=".domicile"
                />
              </Col>
              <Col lg={12}>
                <InputFields
                  label="I am not a US citizen, US resident, or other US person."
                  type="radio"
                  inputType="radio"
                  value="non-resident"
                  model=".domicile"
                />
                {investForm?.primary_investor?.domicile?.toUpperCase() !==
                "U.S. CITIZEN" ? (
                  <p className="formInputError">
                    We are not accepting non-U.S. citizens at this time.
                  </p>
                ) : null}
              </Col>
            </Row>
          </Col>
          <>
            {idUploadRequired ? (
              <>
                <p className="identificationUpload sourceBold">
                  Upload form of identification (Drivers License or Passport)
                  <br />
                  Only jpg, jpeg, png, pdf
                  <span className="required">*</span>
                  <br />
                  (Must be 100mb or less)
                </p>
                <Col
                  lg={12}
                  style={{
                    paddingBottom: !investForm?.showFormError ? "30px" : 0,
                  }}
                >
                  <DocumentUploader />
                </Col>
                {investForm?.showFormError || investForm?.uploadFileError ? (
                  <p
                    className="formInputError"
                    style={{ marginTop: "5px", paddingBottom: "15px" }}
                  >
                    {investForm?.uploadFileError ? (
                      <>
                        File is too large, please upload a file with a size
                        100mb or less.
                        <br />
                      </>
                    ) : null}
                    Identification upload is required*
                  </p>
                ) : null}
              </>
            ) : null}
            {investForm?.primary_investor?.document ? (
              <Col lg={12} className="fileCol">
                <h1 className="filesHeader sourceBold">
                  Uploaded identification
                </h1>
                <div className="fileButtonDiv">
                  <p className="fileButtonName">
                    {investForm?.primary_investor?.document?.documentName}
                  </p>
                  <Button
                    className="fileCloseButton hoverDelay"
                    onClick={() =>
                      this.removeExistingFile(
                        investForm?.primary_investor?.document?.documentName,
                        false
                      )
                    }
                  >
                    X
                  </Button>
                </div>
              </Col>
            ) : null}
            {investForm?.primary_investor?.file?.map((file, i) => (
              <Col key={i} lg={12} className="fileCol">
                <h1 className="filesHeader sourceBold">
                  Uploaded identification
                </h1>
                <div className="fileButtonDiv">
                  <p className="fileButtonName">{file?.name}</p>
                  <Button
                    className="fileCloseButton hoverDelay"
                    onClick={() => this.removeFile(file?.name)}
                  >
                    X
                  </Button>
                </div>
              </Col>
            ))}
          </>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(InvestorDetails);
