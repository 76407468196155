import { combineReducers } from "redux";
import {
  createForms, // optional
} from "react-redux-form";
import {
  GET_IP,
  GET_IP_SUCCESS,
  GET_IP_ERROR,
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_ERROR,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_ERROR,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  TWO_FACTOR,
  TWO_FACTOR_SUCCESS,
  TWO_FACTOR_ERROR,
  LOGOUT,
  SEND_PASSWORD_RESET,
  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_ERROR,
  VERIFY_PASSWORD_RESET_TOKEN,
  VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
  VERIFY_PASSWORD_RESET_TOKEN_ERROR,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  GET_DEAL,
  GET_DEAL_SUCCESS,
  GET_DEAL_ERROR,
  GET_DEALS,
  GET_DEALS_SUCCESS,
  GET_DEALS_ERROR,
  CREATE_DEAL,
  CREATE_DEAL_SUCCESS,
  CREATE_DEAL_ERROR,
  EDIT_DEAL,
  EDIT_DEAL_SUCCESS,
  EDIT_DEAL_ERROR,
  DELETE_DEAL,
  DELETE_DEAL_SUCCESS,
  DELETE_DEAL_ERROR,
  HANDLE_INVESTMENT,
  HANDLE_INVESTMENT_SUCCESS,
  HANDLE_INVESTMENT_ERROR,
  HANDLE_PARTY,
  HANDLE_PARTY_SUCCESS,
  HANDLE_PARTY_ERROR,
  HANDLE_PARTY_KYC_AML_ERROR,
  HANDLE_ENTITY,
  HANDLE_ENTITY_SUCCESS,
  HANDLE_ENTITY_ERROR,
  // HANDLE_ACCOUNT_AND_LINK,
  // HANDLE_ACCOUNT_AND_LINK_SUCCESS,
  // HANDLE_ACCOUNT_AND_LINK_ERROR,
  HANDLE_PAYMENT,
  HANDLE_PAYMENT_SUCCESS,
  HANDLE_PAYMENT_ERROR,
  HANDLE_SIGNATURE,
  HANDLE_SIGNATURE_SUCCESS,
  HANDLE_SIGNATURE_ERROR,
  HANDLE_TRADE,
  HANDLE_TRADE_SUCCESS,
  HANDLE_TRADE_ERROR,
  HANDLE_SUBSCRIPTION_AGREEMENT,
  HANDLE_SUBSCRIPTION_AGREEMENT_SUCCESS,
  HANDLE_SUBSCRIPTION_AGREEMENT_ERROR,
  SEND_DETAILS,
  SEND_DETAILS_SUCCESS,
  SEND_DETAILS_ERROR,
  HANDLE_CANCEL_TOKEN,
  HANDLE_CANCEL_TOKEN_SUCCESS,
  HANDLE_CANCEL_TOKEN_ERROR,
  HANDLE_QUESTIONS,
  HANDLE_QUESTIONS_SUCCESS,
  HANDLE_QUESTIONS_ERROR,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_ERROR,
  EMAIL_SUBSCRIPTIONS,
  EMAIL_SUBSCRIPTIONS_SUCCESS,
  EMAIL_SUBSCRIPTIONS_ERROR,
  HANDLE_COMMENTS_SUCCESS,
  HANDLE_COMMENTS_ERROR,
  HANDLE_OFFERING_INTEREST,
  HANDLE_OFFERING_INTEREST_SUCCESS,
  HANDLE_OFFERING_INTEREST_ERROR,
  GENERATE_PLAID_URL,
  GENERATE_PLAID_URL_SUCCESS,
  GENERATE_PLAID_URL_ERROR,
  GENERATE_STRIPE_URL,
  GENERATE_STRIPE_URL_SUCCESS,
  GENERATE_STRIPE_URL_ERROR,
  UPDATE_PAYMENT_LINK,
  UPDATE_PAYMENT_LINK_SUCCESS,
  UPDATE_PAYMENT_LINK_ERROR,
} from "./constants";

// const initialState = {};

// const userInitialState = {
//   ...this,
//   first_name: "",
//   last_name: "",
//   phone: "",
//   email: "",
//   password: "",
//   loggedIn: false,
// };

const signupInitialState = {
  // first_name: "test",
  // last_name: "Tester",
  // email: "test@test.com",
  // password: "sbr23983",
  // signup_1: true,
  // signup_2: true,
  // signup_3: true,
  // signup_4: true,
};

export const ipAddr = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_IP:
      return {
        isLoading: true,
      };
    case GET_IP_SUCCESS:
      return {
        isLoading: false,
        ip: payload.ip,
      };
    case GET_IP_ERROR:
      return {
        isLoading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export const checkToken = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case REFRESH_USER:
      return {
        isLoading: true,
      };
    case REFRESH_USER_SUCCESS:
      return {
        isLoading: false,
        tokenExpired: false,
      };
    case REFRESH_USER_ERROR:
      return {
        isLoading: false,
        tokenExpired: true,
        error: payload.error,
      };
    default:
      return state;
  }
};

export const alertMessage = (state = {}, { type, payload = "" }) => {
  switch (type) {
    default:
      return state;
  }
};

export const options = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_OPTIONS:
      return {
        isLoading: true,
      };
    case GET_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        ...payload.data,
      };
    case GET_OPTIONS_ERROR:
      return {
        isLoading: false,
        data: null,
      };
    default:
      return state;
  }
};

export const signup = (state = signupInitialState, { type, payload = "" }) => {
  switch (type) {
    case SIGNUP:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case SIGNUP_ERROR:
      return {
        isLoading: false,
        loggedIn: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const user = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case LOGIN:
    case TWO_FACTOR:
      return {
        ...state,
        isLoading: true,
      };
    case TWO_FACTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        login: true,
        isLoading: false,
        ...payload,
      };
    case TWO_FACTOR_ERROR:
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        loggedIn: false,
        error: payload.message,
      };
    case REFRESH_USER_SUCCESS:
      return {
        ...state,
        ...payload.user,
      };
    case LOGOUT:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
};

export const generalAlert = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SIGNUP_SUCCESS:
    case TWO_FACTOR_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        error: null,
      };
    case SIGNUP_ERROR:
    case TWO_FACTOR_ERROR:
    case LOGIN_ERROR:
      return {
        error: payload.message,
      };
    default:
      return state;
  }
};

export const passwordReset = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SEND_PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_PASSWORD_RESET_TOKEN:
    case PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_PASSWORD_RESET_SUCCESS:
      return {
        isLoading: false,
        passwordResetSent: true,
        message: payload.message,
      };
    case SEND_PASSWORD_RESET_ERROR:
      return {
        isLoading: false,
        passwordResetSent: false,
        error: payload.message,
      };
    case VERIFY_PASSWORD_RESET_TOKEN_SUCCESS:
      return {
        isLoading: false,
        verifyPasswordToken: true,
        message: payload.message,
        email: payload.email,
      };
    case VERIFY_PASSWORD_RESET_TOKEN_ERROR:
      return {
        isLoading: false,
        verifyPasswordToken: false,
        error: payload.message,
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        isLoading: false,
        passwordReset: true,
        message: payload.message,
      };
    case PASSWORD_RESET_ERROR:
      return {
        isLoading: false,
        passwordReset: false,
        message: payload.message,
      };
    default:
      return state;
  }
};

export const userActions = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case HANDLE_OFFERING_INTEREST:
    case HANDLE_CANCEL_TOKEN:
      return {
        ...state,
      };
    case HANDLE_QUESTIONS:
    case EMAIL_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case HANDLE_OFFERING_INTEREST_SUCCESS:
    case HANDLE_QUESTIONS_SUCCESS:
    case HANDLE_COMMENTS_SUCCESS:
    case EMAIL_SUBSCRIPTIONS_SUCCESS:
      return {
        isLoading: false,
      };
    // case LOGIN_ERROR:
    case HANDLE_OFFERING_INTEREST_ERROR:
    case SIGNUP_ERROR:
    case HANDLE_QUESTIONS_ERROR:
    case HANDLE_COMMENTS_ERROR:
    case EMAIL_SUBSCRIPTIONS_ERROR:
      return {
        isLoading: false,
        error: payload.message,
      };
    case LOGOUT:
      return {
        isLoading: true,
      };
    case TWO_FACTOR_SUCCESS:
      return {
        access_code: payload?.access_code,
      };
    default:
      return state;
  }
};

export const deal = (state = { ...this }, { type, payload = "" }) => {
  switch (type) {
    case GET_DEAL:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEAL_SUCCESS:
      return {
        isLoading: false,
        // message: payload.message,
        ...payload.deal,
      };
    case GET_DEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    case HANDLE_QUESTIONS_SUCCESS:
    case HANDLE_COMMENTS_SUCCESS:
      return {
        ...payload.deal,
      };
    case GET_DEALS_SUCCESS:
      return {};
    default:
      return state;
  }
};

export const allDeals = (
  state = { ...this, isLoading: false, files: [], images: [] },
  { type, payload = "" }
) => {
  switch (type) {
    case GET_DEALS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEALS_SUCCESS:
      return {
        isLoading: false,
        ...payload,
      };
    case GET_DEALS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const createDeal = (
  state = { ...this, files: {}, images: [] },
  { type, payload = "" }
) => {
  switch (type) {
    case CREATE_DEAL:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_DEAL_SUCCESS:
      return {
        isLoading: false,
        message: payload.message,
        files: {},
        images: [],
      };
    case CREATE_DEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const editDeal = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case EDIT_DEAL:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_DEAL_SUCCESS:
      return {
        isLoading: false,
        message: payload.message,
      };
    case EDIT_DEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const deleteDeal = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case DELETE_DEAL:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_DEAL_SUCCESS:
      return {
        isLoading: false,
        message: payload.message,
      };
    case DELETE_DEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const investForm = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_DEALS_SUCCESS:
      return {};
    case GET_DEAL_SUCCESS:
      return {
        ...state,
        offeringId: payload.deal?.offeringId,
        deal_id: payload.deal?.deal_id,
        ...payload?.investForm,
      };
    case HANDLE_INVESTMENT:
    case HANDLE_PARTY:
    case HANDLE_ENTITY:
    case HANDLE_PAYMENT:
    case HANDLE_SIGNATURE:
    case HANDLE_TRADE:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case GENERATE_PLAID_URL:
    case GENERATE_STRIPE_URL:
    case UPDATE_PAYMENT_LINK:
    case HANDLE_SUBSCRIPTION_AGREEMENT:
      return {
        ...state,
        error: null,
      };
    case HANDLE_INVESTMENT_SUCCESS:
    case HANDLE_PARTY_SUCCESS:
    case HANDLE_ENTITY_SUCCESS:
    case HANDLE_SIGNATURE_SUCCESS:
    case HANDLE_TRADE_SUCCESS:
    case HANDLE_SUBSCRIPTION_AGREEMENT_SUCCESS:
    case HANDLE_PAYMENT_SUCCESS:
    case GENERATE_PLAID_URL_SUCCESS:
    case GENERATE_STRIPE_URL_SUCCESS:
      return {
        ...payload.investForm,
        error: null,
        isLoading: false,
      };
    case UPDATE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case HANDLE_INVESTMENT_ERROR:
    case HANDLE_PARTY_ERROR:
    case HANDLE_ENTITY_ERROR:
    case HANDLE_TRADE_ERROR:
    case HANDLE_SUBSCRIPTION_AGREEMENT_ERROR:
    case HANDLE_PAYMENT_ERROR:
    case HANDLE_SIGNATURE_ERROR:
    case GENERATE_PLAID_URL_ERROR:
    case GENERATE_STRIPE_URL_ERROR:
    case UPDATE_PAYMENT_LINK_ERROR:
      return {
        ...state,
        ...payload,
        error: payload.message,
        isLoading: false,
      };
    case HANDLE_PARTY_KYC_AML_ERROR:
      return {
        ...payload.investForm,
        error: payload.message,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const investFormCurrentModel = (
  state = { model: "investForm.getting_started", page: 0 },
  // state = { model: "investForm.investment_info", page: 1 },
  // state = { model: "investForm.primary_investor", page: 2 },
  // state = { model: "investForm.secondary_investor", page: 3 },
  // state = { model: "investForm.ach_authorization_form", page: 4 },
  // state = { model: "investForm.cc_billing_info", page: 5 },
  // state = { model: "investForm.investor_questionnaire", page: 6 },
  // state = { model: "investForm.subscription_agreement", page: 7 },
  // state = { model: "investForm.fund_transfer", page: 8 },
  { type, payload = "" }
) => {
  switch (type) {
    case HANDLE_INVESTMENT_ERROR:
    case HANDLE_PARTY_ERROR:
    case HANDLE_PARTY_KYC_AML_ERROR:
    case HANDLE_ENTITY_ERROR:
    case HANDLE_PAYMENT_ERROR:
    case HANDLE_SIGNATURE_ERROR:
    case HANDLE_TRADE_ERROR:
      return {
        ...state,
        model: state.previous_model,
        page: state.previous_page,
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export const sendDetails = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SEND_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_DETAILS_SUCCESS:
      return {
        success: true,
        isLoading: false,
      };
    case SEND_DETAILS_ERROR:
      return {
        ...state,
        success: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const cancelInvestment = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case HANDLE_CANCEL_TOKEN:
      return {
        ...state,
        isLoading: true,
      };
    case HANDLE_CANCEL_TOKEN_SUCCESS:
      return {
        isLoading: false,
        investmentInfo: payload.investmentInfo,
        is_cancelled: payload.is_cancelled,
        message: payload.message,
      };
    case HANDLE_CANCEL_TOKEN_ERROR:
      return {
        ...state,
        isLoading: false,
        tokenInvalid: payload.tokenInvalid,
        message: payload.message,
      };
    default:
      return state;
  }
};

export const questions = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_QUESTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_QUESTIONS_SUCCESS:
      return {
        isLoading: false,
        data: payload?.questions,
      };
    case GET_QUESTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload?.message,
      };
    default:
      return state;
  }
};

export default combineReducers({
  ipAddr,
  options,
  user,
  checkToken,
  questions,
  generalAlert,
  ...createForms({
    alertMessage,
    user,
    signup,
    userActions,
    passwordReset,
    deal,
    allDeals,
    createDeal,
    editDeal,
    deleteDeal,
    investForm,
    investFormCurrentModel,
    sendDetails,
    cancelInvestment,
  }),
});
