import axios from "axios";

export function formatDate(dateOnly = false, date = null, removeTime) {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let today;
  if (date) {
    today = new Date(date);
  } else {
    today = new Date();
  }

  let dd = today.getDate();
  let mm = today.getMonth();
  let hh = today.getHours();
  if (date) {
    if (dd < 10) {
      dd = `0${dd}`;
    }
    mm += 1;

    if (mm < 10) {
      mm = `0${mm}`;
    }

    const yyyy = today.getFullYear();
    // return `${yyyy}-${dd}-${mm}`;
    return `${mm}/${dd}/${yyyy}`;
  }
  let h = hh;
  if (h > 12) {
    h = hh - 12;
    // dd = "PM";
  }
  if (h === 0) {
    h = 12;
  }
  let minutes = today.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  mm = months[mm];
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm += 1;
    mm = `0${mm}`;
  }
  if (dateOnly === true) {
    return (today = `${mm} ${dd}, ${yyyy}`);
  } else {
    return (today = `${mm} ${dd}, ${yyyy} ${h}:${minutes}${
      hh >= 12 ? " PM" : " AM"
    }`);
  }
}

export function formatDateWithTime(date) {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let today = date;
  // if (date) {
  //   today = new Date(date);
  // } else {
  //   today = new Date();
  // }

  let dd = today.getDate();
  let mm = today.getMonth();
  let hh = today.getHours();

  let h = hh;
  if (h > 12) {
    h = hh - 12;
    // dd = "PM";
  }
  if (h === 0) {
    h = 12;
  }
  let minutes = today.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  mm = months[mm];
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm += 1;
    mm = `0${mm}`;
  }

  return (today = `${mm} ${dd}, ${yyyy} ${h}:${minutes}${
    hh >= 12 ? " PM" : " AM"
  }`);
}

export function monthDifference(dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7 * 4;
  return Math.abs(Math.round(diff));
}

export function formatTime(time) {
  let hour = time.slice(0, 2);
  let minute = time.slice(3, 6);
  let timeFormat;
  if (hour >= 12) {
    timeFormat = "PM";
    hour = hour - 12;
    if (hour === 0) {
      hour = 12;
    }
  } else {
    timeFormat = "AM";
  }
  // hour = hour - 5;
  if (hour < 0) {
    timeFormat = "PM";
    hour += 12;
  }
  return hour + ":" + minute + " " + timeFormat;
}

// export function formatCurrency(input) {
//   if (!input) return;
//   const strInput = !isNaN(input) ? `$${input}` : input;
//   if (isNaN(parseInt(strInput[strInput.length - 1], 10))) {
//     return strInput?.slice(0, -1);
//   }
//   return strInput?.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

export function formatCurrency(input) {
  if (!input) return;
  // New
  const parsedInput = input?.replace(/[^0-9.]/g, "");
  return currencyFormatter.format(parsedInput);
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function toTitleCase(phrase) {
  return phrase
    ?.toLowerCase()
    ?.split(" ")
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(" ");
}

/**
 *  Parses the JSON returned by a network request
 *
 * @param {object} response A response from a network request
 */
function parseJSON(response) {
  return response.json();
}

function parseBlob(response) {
  return response.blob();
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function nFormatter(num, digits, hideLetter) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  const symbol = hideLetter ? "" : si[i].symbol;
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + symbol;
}

export function nTextFormatter(num) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "Thousand" },
    { value: 1e6, symbol: "Million" },
    { value: 1e9, symbol: "Billion" },
    { value: 1e12, symbol: "Trillion" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  const symbol = si[i].symbol;
  return symbol;
}

export function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export function differenceInDays(date1, date2) {
  let dt1 = new Date(date1);
  let dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
}

export function calculateAge(dob, dt) {
  dt = dt || new Date();
  const diff = dt.getTime() - new Date(dob).getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           An object containing either "data" or "error"
 *
 *
 */

export async function request(formData, url, method, token) {
  const options = {
    headers: {
      "x-access-token": `${token}`,
      "cache-control": "no-cache",
      "Content-Type": "application/json",
    },
    method,
    data: formData,
  };
  return await axios.request(url, options);
}

export async function uploadFiles(formData, url, method, token) {
  const options = {
    headers: { "x-access-token": `${token}` },
    method,
    data: formData,
  };
  return await axios.request(url, options);
}

// export function request(url, options, type) {
//   return fetch(url, options)
//     .then(checkStatus)
//     .then(type === "blob" ? parseBlob : parseJSON)
//     .then((data) => ({
//       data,
//     }))
//     .catch((error) => ({
//       error,
//     }));
// }
